.CreateAccount_form-item-horizontal :global {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-checkbox+span {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        margin-left: 8px;
    }
}